module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"debug":false,"googleAnalytics":{"trackingId":"","autoStart":false,"anonymize":true,"controlCookieName":"GDPR_ANALYTICS"},"hotjar":{"trackingId":"","snippetVersion":6,"controlCookieName":"GDPR_ANALYTICS"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["nl","fr","en"],"defaultLanguage":"nl","redirect":false,"i18nextOptions":{"fallbackLng":"en","debug":false,"keySeparator":false,"nsSeparator":"::","returnEmptyString":false},"pages":[{"matchPath":"/:lang?/(.*)","getLanguageFromPath":true,"excludeLanguages":["nl","fr","en"]},{"matchPath":"/(.*)","getLanguageFromPath":true,"excludeLanguages":["nl","fr","en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
