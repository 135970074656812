export const customerSettings = {
COMPANY_CODE: "lma",
MODE: "production",
VERSION: 1.1,
DOMAIN: 'lma.production2.digex.be',
CMS: 'https://cms.lma.production2.digex.be',
CMS_USERNAME: "gatsby",
CMS_PASSWORD: "AutralisGatsby@2020",  
HEAD_JAVASCRIPT_URLS: [],
BODY_JAVASCRIPT_URLS: ['https://dealerlocator.dieteren.be/react/js/dealerLocatorWidget.js'],
LANGUAGES: ['nl', 'fr', 'en'],
FORCE_SINGLE_LANGUAGE_IN_URL: false,

GOOGLE_ANALYTICS_IDENTIFICATION: '',
GOOGLE_ANALYTICS_ID: '',
GOOGLE_TAG_MANAGER_ID: 'GTM-TKJQX59',
GDDL_ID: 'GTM-TKJQX59',
HOTJAR_ID: '',
MAPBOX_TOKEN: 'pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w',
RECAPTCHA_KEY: '6LcZyaUZAAAAAOrMBxDUfdVDq2xKcHORceibmov3',
INTERCOM_ID: '',
FINANCING: true,
FINANCING_TEXT: true,
FAVORITES: true,
VEHICLE_COMPARE: true,
CONSENT: false,
CONSENT_SCRIPT_URL: 'TODO //nexus.ensighten.com/dieteren/lma_573_prod/Bootstrap.js',
SEO: true
};